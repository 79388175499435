import { useState } from "react";

const RadioGroup = ({
    label,
    name,
    defaultValue,
    options = [],
    onChange = () => { }
}) => {
    const [state, setState] = useState(defaultValue);

    const handleChange = (event) => {
        let newState = event.target.value;

        setState(newState)
        onChange(name, newState)
    };

    return (
        <div className="block">
            <span className="text-gray-700">{label}</span>
            {options.map(option => (
                <div key={option} className="mt-2">
                    <label className="inline-flex items-center">
                        <input type="radio" value={option} name={name} className="border-gray-300 border-2 text-primary focus:border-primary focus:ring-primary" onChange={handleChange} checked={option === state} />
                        <span className="ml-2">{option}</span>
                    </label>
                </div>
            ))}
        </div>
    );
}

export default RadioGroup;