import { Switch, Route, } from "react-router-dom";
import UserProfileEdit from "./UserProfileEdit";
import OfferEdit from "./OfferEdit";
import ConfigurationScreen from "./ConfigurationScreen";

function App() {
  return (
    <div>
      <Switch>
        <Route path="/admin/config">
          <ConfigurationScreen />
        </Route>
        <Route path="/admin/offer/:id/edit">
          <OfferEdit />
        </Route>
        <Route path="/">
          <UserProfileEdit />
        </Route>
      </Switch>
    </div>
  );
}

export default App;
