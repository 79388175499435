const Button = ({
    children,
    ...rest
}) => {
    return (
        <button {...rest} className="bg-primary hover:opacity-90 text-white font-bold py-4 px-6 rounded">
            {children}
        </button>
    )
};

export default Button;