import { useState } from "react";

const CheckGroup = ({
    label,
    name,
    limit,
    defaultValue = [],
    options = [],
    onChange = () => { }
}) => {
    const [state, setState] = useState(defaultValue);

    const handleChange = (event) => {
        let newState;
        const value = event.target.value;
        const isChecked = event.target.checked;

        if (isChecked) {
            newState = [...state, value];
        } else {
            newState = state.filter((item) => item !== value);
        }

        setState(newState)
        onChange(name, newState)
    };

    const isDisabled = (option) => {
        return (state.length >= limit && !state.includes(option));
    }

    return (
        <div className="block">
            <span className="text-gray-700">{label}</span>
            {options.map(option => (
                <div key={option} className="mt-2">
                    <label className="inline-flex items-center">
                        <input
                            className={`${isDisabled(option) ? "opacity-30 bg-gray-300" : ""} border-gray-300 border-2 text-primary focus:border-primary focus:ring-primary`}
                            type="checkbox"
                            value={option}
                            name={name}
                            onChange={handleChange}
                            disabled={isDisabled(option)}
                            checked={state.includes(option)} />
                        <span className={`ml-2 ${isDisabled(option) ? "opacity-30" : ""}`}>{option}</span>
                    </label>
                </div>
            ))}
        </div>
    );
}

export default CheckGroup;