import { useState } from "react";

const Input = ({
    label,
    name,
    placeholder,
    type = "text",
    defaultValue = "",
    onChange = () => { }
}) => {
    const [state, setState] = useState(defaultValue);

    const handleChange = (event) => {
        const newState = event.target.value;

        setState(newState)
        onChange(name, newState)
    };

    return (
        <label className="block">
            <span className="text-gray-700">{label}</span>
            <input
                className="mt-0 block w-full px-0.5 border-0 border-b-2 border-gray-200 focus:ring-0 focus:border-primary"
                name={name}
                placeholder={placeholder}
                type={type}
                value={state}
                onChange={handleChange}
            />
        </label>
    )
};

export default Input;