import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome } from '@fortawesome/free-solid-svg-icons';
import { LEGACY_APP_URL } from '../constants';

const Header = () => (
    <header className="bg-primary w-full h-12 pl-10 shadow-md flex flex-row items-center filter drop-shadow-lg">
        <FontAwesomeIcon icon={faHome} size="lg" color="white" />
        <h1 className="ml-3 font-black text-white tracking-tighter">EMMA</h1>
        <div className="flex space-x-4 uppercase text-sm text-white mx-20 h-full items-center">
            <a
                className="px-10 py-4 h-full opacity-70 font-ligth"
                href={`${LEGACY_APP_URL}/admin/indicators`}>
                Indicadores
            </a>
            <a
                className="px-10 py-4 h-full opacity-70 font-ligth"
                href={`${LEGACY_APP_URL}/admin/employers?orderBy=requestDate&desc=false`}>
                Ofertas
            </a>
            <a
                className="px-10 py-4 h-full opacity-70 font-ligth"
                href={`${LEGACY_APP_URL}/admin/adalabers`}>
                Adalabers
            </a>
            <a
                className="px-10 py-4 h-full opacity-70 font-light border-b-2 border-yellow-400 opacity-100"
                href="#active">
                Configuración
            </a>
        </div>
    </header>
)

export default Header;