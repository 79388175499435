import { useState, useEffect } from "react";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleNotch } from "@fortawesome/free-solid-svg-icons";
import firebase, { db } from "./firebase";
import Input from "./components/Input";
import Button from "./components/Button";
import Header from "./components/Header";
import Page from "./components/Page";
import Container from "./components/Container";
import Card from "./components/Card";
import Alert from "./components/Alert";
import Select from "./components/Select";

const formattedDate = (date) => {
  if (!date) return undefined;

  return moment(date).format("YYYY-MM-DD");
};

function ConfigurationScreen() {
  const [isLoading, setIsLoading] = useState(true);
  const [generalConfig, setGeneralConfig] = useState({
    currentPromo: null,
    hideOffersToCurrentPromoToDate: null,
    currentBootcamp: null,
  });

  const [showAlert, setShowAlert] = useState(false);

  useEffect(() => {
    // TODO: Extract to hook
    const params = new URLSearchParams(window.location.search);

    if (params.has("token")) {
      firebase.auth().signInWithCustomToken(params.get("token"));
    }
    // TODO END

    const docRef = db.collection("config").doc("general");
    docRef
      .get()
      .then((doc) => {
        const {
          currentPromo,
          hideOffersToCurrentPromoToDate,
          currentBootcamp,
        } = doc.data();

        setGeneralConfig({
          currentPromo,
          currentBootcamp,
          hideOffersToCurrentPromoToDate:
            hideOffersToCurrentPromoToDate?.toDate(),
        });
      })
      .finally(() => setIsLoading(false));
  }, []);

  const handleChange = (name, value) => {
    setGeneralConfig((prevConfig) => ({ ...prevConfig, [name]: value }));
  };

  const handleUpdate = () => {
    const docRef = db.collection("config").doc("general");
    const newConfig = {
      currentPromo: generalConfig.currentPromo,
      currentBootcamp: generalConfig.currentBootcamp,
    };

    if (generalConfig.hideOffersToCurrentPromoToDate) {
      newConfig.hideOffersToCurrentPromoToDate =
        firebase.firestore.Timestamp.fromDate(
          moment(generalConfig.hideOffersToCurrentPromoToDate).toDate()
        );
    }

    docRef.set(newConfig).then(() => {
      setShowAlert(true);
      setTimeout(() => {
        setShowAlert(false);
      }, 2000);
    });
  };

  if (isLoading) {
    return (
      <div className="bg-primary flex justify-center items-center h-screen">
        <FontAwesomeIcon icon={faCircleNotch} size="3x" spin color="white" />
      </div>
    );
  }

  return (
    <Page>
      <Header />
      <Container>
        <Card className="min-h-3/4-screen">
          <h1 className="text-base font-medium uppercase">General</h1>
          <p className="text-lg my-2 mb-8">
            Configuraciones generales de la bolsa de empleo.
          </p>

          <div className="grid grid-cols-1 gap-6 lg:max-w-1/2 mb-8">
            <Select
              label="Elige el bootcamp"
              name="currentBootcamp"
              onChange={handleChange}
              options={["Programación web", "Data analyst"]}
              defaultValue={generalConfig.currentBootcamp}
            />
          </div>

          <div className="grid grid-cols-1 gap-6 lg:max-w-1/2 mb-8">
            <Input
              label="Promoción actual"
              name="currentPromo"
              type="text"
              defaultValue={generalConfig.currentPromo}
              onChange={handleChange}
            />
            <Input
              label="La promoción actual puede ver ofertas a partir de esta fecha"
              name="hideOffersToCurrentPromoToDate"
              type="date"
              defaultValue={formattedDate(
                generalConfig.hideOffersToCurrentPromoToDate
              )}
              onChange={handleChange}
            />
          </div>

          <Button onClick={handleUpdate}>Guardar</Button>
        </Card>
        <Alert
          text="¡Los cambios se han guardado correctamente!"
          show={showAlert}
        />
      </Container>
    </Page>
  );
}

export default ConfigurationScreen;
