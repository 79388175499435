import { useState, useEffect } from "react";

const Alert = ({ text, show = false }) => {
    const [state, setState] = useState(show);

    useEffect(() => {
        setState(show);
    }, [show]);

    if (!state)
        return null;

    return (
        <div
            className="absolute top-1/10 right-1/10 px-6 py-5 leading-normal text-white bg-green-400 rounded-lg filter drop-shadow-lg"
            role="alert">
            <p>{text}</p>
        </div>
    );
}

export default Alert;