import { useState, useRef } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCloudUploadAlt, faCircleNotch } from '@fortawesome/free-solid-svg-icons';

const UploadFile = ({
    label,
    name,
    text,
    defaultValue,
    uploading = false,
    uploadingText,
    onChange = () => { }
}) => {
    const [state, setState] = useState(defaultValue);
    const hiddenFileInput = useRef();

    const handleClick = (event) => {
        hiddenFileInput.current.click();
        event.preventDefault();
    }

    const handleChange = (event) => {
        const files = event.target.files;

        if (files.length > 0) {
            setState(files[0].name);
            onChange(name, files);
        }
    }

    const internalText = uploading ? uploadingText
         : state ? state.substring(0, 20) + "..."
         : text;

    return (
        <label className="block">
            <span className="text-gray-700">{label}</span>
            <>
                <button className="mt-2 w-full max-w-xs h-14 bg-none border-2 border-primary hover:opacity-90 text-gray-700 font-bold rounded flex items-center" onClick={handleClick}>
                    <span className="bg-primary h-full w-14 inline-flex items-center justify-center">
                        {uploading ? (
                            <FontAwesomeIcon icon={faCircleNotch} size="lg" spin />
                        ) : (
                            <FontAwesomeIcon icon={faCloudUploadAlt} size="lg" />
                        )}
                    </span>
                    <span className="p-2">{internalText}</span>
                </button>
                <input ref={hiddenFileInput} type="file" name={name} className="hidden" onChange={handleChange} />
            </>
        </label>
    );
}

export default UploadFile;