import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { db } from "./firebase";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleNotch, faHome } from '@fortawesome/free-solid-svg-icons';
import Input from "./components/Input"
import RadioGroup from "./components/RadioGroup";
import Button from "./components/Button";
import moment from "moment";
import { LEGACY_APP_URL } from "./constants";

function OfferEdit() {
  const { id } = useParams();
  // const location = useLocation();
  // const searchParams = new URLSearchParams(location.search);
  const [isLoading, setIsLoading] = useState(true);
  const [offer, setOffer] = useState({});

  useEffect(() => {
    var docRef = db.collectionGroup("opportunities");
    docRef.get().then((querySnapshot) => {
      querySnapshot.forEach((doc) => {
        if (doc.id === id) {
          setOffer(doc.data())
          setIsLoading(false);
        }
      });
    });
  }, [id]);

  const handleChange = (name, value) => {
    setOffer(prevOffer => ({ ...prevOffer, [name]: value }))
  }

  const handleSubmit = (event) => {
    event.preventDefault();

    const attrs = { ...offer };

    const docRef = db
      .collection("employerUsers")
      .doc(offer.employer)
      .collection("opportunities")
      .doc(id);

    
    docRef.set(attrs).then(() => {
      window.location.replace(`${LEGACY_APP_URL}/admin/employers/${id}`)
    });
  }

  if (isLoading) {
    return (
      <div className="bg-primary flex justify-center items-center h-screen">
        <FontAwesomeIcon icon={faCircleNotch} size="3x" spin color="white" />
      </div>
    )
  }

  return (
    <div className="bg-gradient-to-tl to-white from-primary flex flex-col justify-center items-center">
      <header className="bg-primary w-full p-3 pl-10 shadow-md flex flex-row">
        <FontAwesomeIcon icon={faHome} size="lg" color="white" />
        <h1 className="ml-3 font-black text-white tracking-tighter">EMMA</h1>

      </header>
      <div className="bg-white shadow-md my-8 rounded">
        <form onSubmit={handleSubmit}>
          <div className="w-screen max-w-3xl p-8">
            <div className="grid grid-cols-1 gap-6">
              <RadioGroup label="1. Perfil técnico" name="technicalProfile" onChange={handleChange} defaultValue={offer.technicalProfile} options={["Maquetación", "Maquetación + JavaScript", "JavaScript", "React"]} />
              <RadioGroup label="2. Área de proyección" name="profileProjection" onChange={handleChange} defaultValue={offer.profileProjection} options={["Maquetación / Diseño / UX", "Programación"]} />
              <RadioGroup label="3. Nivel de inglés mínimo requerido" name="englishLevel" onChange={handleChange} defaultValue={offer.englishLevel} options={["No es necesario", "B1", "B2", "C1", "C2"]} />
              <RadioGroup label="4. Tipo de contrato" name="contractType" onChange={handleChange} defaultValue={offer.contractType} options={["Contrato indefinido", "Contrato temporal (mínimo 6 meses)", "Contrato de servicios (autónoma)", "Otro"]} />
              <RadioGroup label="5. Escala salarial" name="salaryRange" onChange={handleChange} defaultValue={offer.salaryRange} options={["16.000 - 18.000 €", "18.000 - 20.000 €", "20.000 - 22.000€", "22.000 - 24.000 €", "más de 24.000 €"]} />
              <RadioGroup label="6. Modalidad del puesto de trabajo" name="workingMode" onChange={handleChange} defaultValue={offer.workingMode} options={["100% remoto", "100% presencial", "Semipresencial", "Remoto situación sanitaria", "Otro"]} />
              <RadioGroup label="7. ¿Existe posibilida de jornada reducida?" name="reducedSchedule" onChange={handleChange} defaultValue={offer.reducedSchedule} options={["Sí", "No"]} />
              <RadioGroup label="8. ¿Es requisito indispensable que las candidatas posean un título universitario?" name="universitaryStudies" onChange={handleChange} defaultValue={offer.universitaryStudies} options={["Sí", "No"]} />
              <Input label="9. Fecha de incorporación aproximada" name="incorporationDate" type="date" defaultValue={moment(offer.incorporationDate).format('YYYY-MM-DD')} onChange={handleChange} />
              <Input label="10. Resumen del puesto de trabajo" name="positionSummary" type="text" defaultValue={offer.positionSummary} onChange={handleChange} />
              <Input label="11. Por qué aplicar a esta oferta" name="motivational" type="text" defaultValue={offer.motivational} onChange={handleChange} />
              <Input label="12. Qué apoyo vas a tener en la empresa" name="apoyoEnLaEmpresa" type="text" defaultValue={offer.apoyoEnLaEmpresa} onChange={handleChange} />
              <Input label="13. Mail de contacto" name="contactEmail" type="text" defaultValue={offer.contactEmail} onChange={handleChange} />
              <Input label="14. Comentarios" name="comments" type="text" defaultValue={offer.comments} onChange={handleChange} />

              <Button>Guardar</Button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}

export default OfferEdit;