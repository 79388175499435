import { useState, useEffect } from "react";
import firebase, { db, storage } from "./firebase";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleNotch, faHome } from "@fortawesome/free-solid-svg-icons";
import Input from "./components/Input";
import RadioGroup from "./components/RadioGroup";
import CheckGroup from "./components/CheckGroup";
import Select from "./components/Select";
import UploadFile from "./components/UploadFile";
import Button from "./components/Button";
import { LEGACY_APP_URL } from "./constants";

function UserProfileEdit() {
  const [isLoading, setIsLoading] = useState(true);
  const [uploadingCV, setUploadingCV] = useState({});
  const [user, setUser] = useState();

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);

    if (params.has("token")) {
      firebase
        .auth()
        .signInWithCustomToken(params.get("token"))
        .then((userCredential) => {
          var user = userCredential.user;
          var docRef = db.collection("adalabers").doc(user.uid);

          docRef
            .get()
            .then((doc) => {
              setUser({ ...doc.data(), uid: user.uid });
            })
            .finally(() => setIsLoading(false));
        });
    }
  }, []);

  const handleChange = (name, value) => {
    setUser((prevUser) => ({ ...prevUser, [name]: value }));
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    const attrs = { ...user, new: false };
    const docRef = db.collection("adalabers").doc(user.uid);

    docRef.set(attrs).then(() => {
      window.location.replace(LEGACY_APP_URL);
    });
  };

  const handleChangeFile = (name, files) => {
    const storageRef = storage.ref();
    const language = name === "cvEnURL" ? "english" : "spanish";
    const fileRef = storageRef.child(`CV2/${user.fullName} - ${language}.pdf`);

    if (files.length > 0) {
      setUploadingCV({ [language]: true });

      fileRef
        .put(files[0], { contentType: "application/pdf" })
        .then(async (snapshot) => {
          const downloadURL = await fileRef.getDownloadURL();
          handleChange(name, downloadURL);
          setUploadingCV({ [language]: false });
        });
    } else {
      handleChange(name, null);
    }
  };

  if (isLoading) {
    return (
      <div className="bg-primary flex justify-center items-center h-screen">
        <FontAwesomeIcon icon={faCircleNotch} size="3x" spin color="white" />
      </div>
    );
  }

  return (
    <div className="bg-gradient-to-tl to-white from-primary flex flex-col justify-center items-center">
      <header className="bg-primary w-full p-3 pl-10 shadow-md flex flex-row">
        <FontAwesomeIcon icon={faHome} size="lg" color="white" />
        <h1 className="ml-3 font-black text-white tracking-tighter">EMMA</h1>
      </header>
      <div className="bg-white shadow-md my-8 rounded">
        <form onSubmit={handleSubmit}>
          <div className="w-screen max-w-3xl p-8">
            <div className="grid grid-cols-1 gap-6">
              <Input
                label="0. Nombre completo"
                name="fullName"
                type="text"
                defaultValue={user.fullName}
                onChange={handleChange}
              />
              <Input
                label="1. Ubicación"
                name="additionalLocations"
                type="text"
                defaultValue={user.additionalLocations}
                onChange={handleChange}
              />
              <RadioGroup
                label="2. Disponibilidad geográfica para viajar"
                name="movilidad"
                onChange={handleChange}
                defaultValue={user.movilidad}
                options={["Sí", "No"]}
              />
              <Select
                label="3. Selecciona si has obtenido o no alguno de estos títulos en los últimos 5 años"
                name="apprenticeshipContract"
                onChange={handleChange}
                options={[
                  "Título universitario",
                  "Formación profesional de grado medio o superior",
                  "Certificado de profesionalidad",
                  "Ninguno de los anteriores en los últimos 5 años",
                ]}
                defaultValue={user.apprenticeshipContract}
              />
              <Select
                label="4. Nivel de estudios"
                name="studies"
                onChange={handleChange}
                options={[
                  "Sin estudios obligatorios",
                  "ESO",
                  "Bachillerato",
                  "FP Básica",
                  "FP Media",
                  "FP Superior",
                  "Estudios universitarios",
                  "Grado",
                  "Máster / Postgrado",
                ]}
                defaultValue={user.studies}
              />
              <Input
                label="5. Descripción de estudios"
                name="studiesDescription"
                type="text"
                defaultValue={user.studiesDescription}
                onChange={handleChange}
              />
              <CheckGroup
                label="6. Marca las softskills en las que destacas"
                name="softskills"
                onChange={handleChange}
                defaultValue={user.softskills}
                limit={3}
                options={[
                  "Tengo una mentalidad abierta. Soy flexible y adaptable",
                  "Soy innovadora, creativa e imaginativa",
                  "Soy colaboradora y trabajo en equipo",
                  "Soy autónoma y tengo capacidad de toma de decisiones",
                  "Busco el aprendizaje continuo y la autoformación",
                  "Tengo pensamiento crítico. Soy curiosa e inquieta",
                  "Poseo habilidades comunicativas",
                  "Soy resolutiva y resiliente ante imprevistos",
                  "Soy implicada y comprometida",
                  "Me oriento hacia los resultados",
                  "Me oriento hacia la calidad y los detalles",
                ]}
              />
              <CheckGroup
                label="7. Indica si tienes experiencia en algunas de estas tareas"
                name="experiencia"
                onChange={handleChange}
                defaultValue={user.experiencia}
                options={[
                  "Trato directo con clientes",
                  "Gestión de equipos",
                  "Priorización de tareas",
                  "Planificación de tareas",
                  "Realización de presentaciones",
                  "Metodologías de trabajo (framework Agile)",
                  "Trabajo en remoto",
                  "Estimación de tareas y cumplimiento de plazos",
                ]}
              />
              <Select
                label="8. Nivel de Inglés"
                name="englishLevel"
                onChange={handleChange}
                options={[
                  "A1.1 - A1.2",
                  "A1.2 - A2.1",
                  "A2.1 - A2.2",
                  "B1.1 - B1.2",
                  "B2.1 - B2.2",
                  "B2.2 - C1.1",
                  "C1.2 - C2.1",
                  "C2.1 - C2.2",
                  "Nativo",
                ]}
                defaultValue={user.englishLevel}
              />
              <Input
                label="9. Nivel de otros idiomas distintos al inglés"
                name="otrosIdiomas"
                type="text"
                defaultValue={user.otrosIdiomas}
                onChange={handleChange}
              />
              <Input
                label="10. Mi perfil de Github"
                name="github"
                type="text"
                defaultValue={user.github}
                onChange={handleChange}
              />
              <Input
                label="11. Mi perfil de LinkedIn"
                name="linkedin"
                type="text"
                defaultValue={user.linkedin}
                onChange={handleChange}
              />
              <Input
                label="12. Mi teléfono"
                name="tel"
                type="text"
                defaultValue={user.tel}
                onChange={handleChange}
              />
              <Input
                label="13. Breve relato profesional"
                name="shortBio"
                type="text"
                defaultValue={user.shortBio}
                onChange={handleChange}
              />
              <Input
                label="14. Otros conocimientos"
                name="additionalComments"
                type="text"
                defaultValue={user.additionalComments}
                onChange={handleChange}
              />
              <Input
                label="15. Página web o portafolio"
                name="portafolio"
                type="text"
                defaultValue={user.portafolio}
                onChange={handleChange}
              />
              <UploadFile
                label="16. Sube tu CV en PDF en español"
                name="cvURL"
                defaultValue={user.cvURL}
                onChange={handleChangeFile}
                uploading={uploadingCV.spanish}
                text="Elegir curriculum"
                uploadingText="Subiendo..."
              />
              <UploadFile
                label="17. Sube tu CV en PDF en inglés"
                name="cvEnURL"
                defaultValue={user.cvEnURL}
                onChange={handleChangeFile}
                uploading={uploadingCV.english}
                text="Elegir curriculum"
                uploadingText="Subiendo..."
              />

              <Button>Guardar</Button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}

export default UserProfileEdit;
