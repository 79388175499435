import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyB-6FW2snuEIre0TWn-CYWlOx3excO3hFA",
  authDomain: "matcher-platform.firebaseapp.com",
  databaseURL: "https://matcher-platform.firebaseio.com",
  projectId: "matcher-platform",
  storageBucket: "matcher-platform.appspot.com",
  messagingSenderId: "847749105158",
  appId: "1:847749105158:web:3d311a4894cc141ca62587",
};

// const firebaseConfig = {
//   apiKey: "AIzaSyCTJF4x9YKYwY2mh72UsQmz0tlAgQuLzI0",
//   authDomain: "emma-demo-env.firebaseapp.com",
//   projectId: "emma-demo-env",
//   storageBucket: "emma-demo-env.appspot.com",
//   messagingSenderId: "924407171545",
//   appId: "1:924407171545:web:46a29f5a9a4ea3a87ef1c8",
// };

firebase.initializeApp(firebaseConfig);

const db = firebase.firestore();
const storage = firebase.storage();

export { firebase as default, db, storage };
